// This is the only place we want to use next/image as this correctly wraps
// next/image to handle/force the user to use relative cloudinary image urls
// but still support additional transforms via the loader infrastructure
// eslint-disable-next-line no-restricted-imports
import NextImage, { ImageLoader, ImageProps } from 'next/image'

import styled from '@emotion/styled'
import getPublicIdFromCloudinaryUrl from '../utils/getPublicIdFromCloudinaryUrl'
import isCloudinaryUrl from '../utils/isCloudinaryUrl'
import makeCloudinaryImageUrl from '../utils/makeCloudinaryImageUrl'
import isBynderUrl from '../utils/isBynderUrl'
import makeBynderImageUrl from '../utils/makeBynderImageUrl'

interface ImageOptions {
  aspectRatio?: string
  transformations?: string[]
}

const cloudinaryLoader =
  ({ aspectRatio, transformations }: ImageOptions): ImageLoader =>
  ({ src, width }) => {
    const mergedTransformations = [
      'q_auto',
      'f_auto',
      aspectRatio ? `w_${width},c_fill,ar_${aspectRatio}` : `w_${width}`,
      ...(transformations || []),
    ]
    return makeCloudinaryImageUrl({
      publicId: getPublicIdFromCloudinaryUrl(src),
      transformations: mergedTransformations,
    })
  }

const bynderLoader =
  ({ aspectRatio, transformations }: ImageOptions): ImageLoader =>
  ({ src, width }) => {
    const calculateHeight = () => {
      if (!aspectRatio) return null
      const splitRatio = aspectRatio.split(':')
      const rat1 = Number(splitRatio[0])
      const rat2 = Number(splitRatio[1])
      const ratio = width / rat1

      return Math.round(ratio * rat2)
    }
    const mergedTransformations = [
      aspectRatio
        ? `transform:fill,width:${width},height:${calculateHeight()}`
        : `transform:fill,width:${width}`,
      ...(transformations || []),
    ]

    return makeBynderImageUrl({
      src,
      transformations: mergedTransformations,
    })
  }

export const getLoader = (
  src: ImageProps['src'],
  options: ImageOptions,
): { src: ImageProps['src']; loader: ImageLoader | undefined } => {
  if (typeof src === 'string' && isCloudinaryUrl(src)) {
    return {
      src,
      loader: cloudinaryLoader(options),
    }
  }

  if (typeof src === 'string' && isBynderUrl(src)) {
    return {
      src,
      loader: bynderLoader(options),
    }
  }

  // undefined means use the default next/image loader (e.g static image data or NOT cloudinary/bynder)
  return { src, loader: undefined }
}

type Props = Omit<ImageProps, 'loader' | 'alt'> &
  Partial<Pick<ImageProps, 'alt'>> & {
    aspectRatio?: string // must be W:H (can be absurd values like 526:426)
    transformations?: string[]
  }

const StyledImage = styled(NextImage)`
  pointer-events: none;
`

const Image = ({ src, transformations, aspectRatio, ...others }: Props) => {
  // The Next.JS loader infrastructure always expects a 'relative' url so we override it in case of a custom loader
  // When its the default Next.JS loader we always supply the full url so Next/Image can proxy the request
  const { src: overridenSrc, loader } = getLoader(src, {
    aspectRatio,
    transformations,
    ...others,
  })

  // eslint-disable-next-line jsx-a11y/alt-text
  return (
    <StyledImage
      {...others}
      alt={others.alt ?? ''}
      src={overridenSrc}
      loader={loader}
    />
  )
}

export default Image
