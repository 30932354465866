import {
  AssetStoryblok,
  BynderImageStoryblok as BynderImageStoryblokType,
} from '../bloks/storyblok.generated'
import mapCloudinaryAssetName from './mapCloudinaryAssetName'

interface BynderImage {
  id: string
  name?: string
  files: {
    transformBaseUrl: {
      url: string
      width: number | null
      height: number | null
      fileSize: number | null
    }
  }
  databaseId: string
  description?: string | null
}

interface BynderImageStoryblok extends BynderImageStoryblokType {
  alt?: string
  title?: string
  image: BynderImage[]
  component: 'bynderImage'
}

type ImageData = {
  src: string
  alt: string
  id?: string | number
}

const isBynderImage = (unknown: unknown): unknown is BynderImageStoryblok => {
  return (unknown as BynderImageStoryblok)?.image?.[0]?.files !== undefined
}
/**
 *
 * @param image the image data from the CMS to map in a structured way, can be empty
 * @returns for empty or invalid entries returns undefined, otherwise returns the correctly formatted image data
 */
export const mapImage = (
  image:
    | AssetStoryblok
    | string
    | Array<BynderImageStoryblok>
    | BynderImageStoryblok
    | undefined,
): ImageData | undefined => {
  if (!image) {
    return undefined
  }

  // 'Legacy' image field handling
  if (typeof image === 'string') {
    return { src: image, alt: '' }
  }

  const imageObject = Array.isArray(image) ? image[0] : image

  if (isBynderImage(imageObject)) {
    const baseUrl = imageObject.image[0].files.transformBaseUrl?.url
    const lastSlashIndex = baseUrl.lastIndexOf('/')
    const seoTitle =
      imageObject.title && imageObject.title?.length > 1
        ? imageObject.title.replaceAll(' ', '-')
        : null
    const formattedUrl =
      seoTitle && lastSlashIndex !== -1
        ? `${baseUrl.substring(0, lastSlashIndex)}/${seoTitle}`
        : baseUrl

    return {
      src: formattedUrl,
      alt: imageObject.alt ?? '',
      id: imageObject.image[0].id,
    }
  }

  // 'isCloudinaryImage
  if (
    typeof imageObject?.filename === 'string' &&
    // Sometimes storyblok 'Clears" it to an empty string, we want to handle this as 'no image'
    imageObject.filename !== ''
  ) {
    return {
      src: imageObject.filename,
      alt: mapCloudinaryAssetName(imageObject),
      id: imageObject.id,
    }
  }

  return undefined
}

export const getRequiredImageFallback = () => ({ src: '', alt: undefined })
