import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { ChangeEvent } from 'react'
import FormFieldSelect from '../../components/FormFieldSelect'

const Container = styled.div(({ theme }) => [
  css`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    font-weight: 400;
    letter-spacing: initial;
    padding: ${theme.spacing.x1}px ${theme.spacing.x4}px;
    background: ${theme.colors.xplorer.secondaryDamenYachtingBeach};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x2}px ${theme.spacing.x4}px;
    }
  `,
])

const SelectWrapper = styled.div(() => [
  css`
    display: flex;
    justify-content: flex-start;
  `,
])

export interface TopBarProps {
  label: string
  links: {
    title: string
    link: string
    linkTarget?: '' | '_blank'
  }[]
}

const TopBar = ({ links, label }: TopBarProps) => {
  if (!links.length) return null

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const url = e.target
    if (!url) return

    const selectedLink = links.find(
      (item) =>
        item.link === url.item(url.selectedIndex)?.value &&
        item.title === url.item(url.selectedIndex)?.innerText,
    )

    e.target.selectedIndex = 0
    window.open(selectedLink?.link, selectedLink?.linkTarget || '_self')
  }

  return (
    <Container>
      <SelectWrapper>
        <FormFieldSelect
          variant="topbar"
          label={label}
          labelPlacement="hidden"
          noInitialOption
          colorScheme="dark"
          onChange={handleSelectChange}
          options={links.map(({ title, link }) => ({
            key: link,
            value: title,
          }))}
        />
      </SelectWrapper>
    </Container>
  )
}

export default TopBar
