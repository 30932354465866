import { PageStoryblok } from '../bloks/storyblok.generated'
import { Props as HeadProps } from '../components/Head'
import stripEmpty from './stripEmpty'
import stripUndefined from './stripUndefined'

const createSeoHeadParams = (
  page: {
    seo?: PageStoryblok['seo']
    robots?: PageStoryblok['robots']
  },
  slug: string,
  hostName: string | undefined,
): HeadProps => {
  const seoTags = stripEmpty(page.seo ?? {})

  return stripUndefined({
    title: page.seo?.title,
    description: seoTags.description,
    robots:
      typeof page.robots === 'string'
        ? page.robots ?? process.env.SEO_ROBOTS_DEFAULT ?? 'noindex, nofollow'
        : process.env.SEO_ROBOTS_DEFAULT ?? 'noindex, nofollow',
    openGraph: {
      description: seoTags.og_description,
      image: seoTags.og_image,
      title: seoTags.og_title,
    },
    twitter: {
      description: seoTags.twitter_description,
      image: seoTags.twitter_image,
      title: seoTags.twitter_title,
    },
    canonical: `${hostName}/${slug}`,
  })
}

export default createSeoHeadParams
